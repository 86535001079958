<template>
  <div class="home">
    <img id="headimg" class="rotate" alt="My spinning head" src="../img/head.png">
    <HelloWorld msg="Coming soon!" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
<style>
.rotate {
  animation: rotation 10s infinite linear;
}

#headimg {
  border-radius: 50px;
  max-height: 100vw;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
</style>
